<template>
  <div class="add">
    <Breadcrumb name1="系列系列" name2="添加系列"/>
    <!--    卡片视图-->
    <el-card>
      <!--      提示区-->
      <el-alert
        title="添加系列"
        type="info"
        center
        :closable="false"
        show-icon
      >
      </el-alert>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-position="top"
        label-width="100px"
      >
        <el-tabs
          :tab-position="'left'"
        >
          <el-tab-pane label="基本信息" name="0">
            <!-- <el-form-item label="系列背景图" prop="bg_url">
              <el-input v-model="addForm.bg_url"></el-input>
            </el-form-item> -->
            <el-form-item label="系列背景图" prop="bg_image">
              <el-upload
                class="upload-demo"
                drag
                action="alert"
                :on-change="handlePreview"
                :auto-upload="false"
                :show-file-list="false"
                :file-list="fileList"
                :multiple="false"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Drop file here or <em>click to upload</em>
                </div>
              </el-upload>
              <div class="block" v-if="fileurl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="fileurl"
                ></el-image>
              </div>
            </el-form-item>
            <el-form-item label="系列名称" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <!-- 级联选择器 -->
            <el-form-item label="艺术家" prop="artist_id">
              <el-select v-model="addForm.artist_id" placeholder="请选择">
                <el-option
                  v-for="item in artistList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="官网url" prop="website_url">
              <el-input v-model="addForm.website_url"></el-input>
            </el-form-item>
            <el-form-item label="系列描述" prop="describe">
              <el-input v-model="addForm.describe" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="抽奖结果url" prop="result_url">
              <el-input v-model="addForm.result_url"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="result_url">
              <el-input v-model="addForm.sort"></el-input>
            </el-form-item>
            <el-button type="primary" class="AddBtn" @click="submitImageForm" v-if="id===0">添加</el-button>
            <el-button type="primary" class="AddBtn" @click="submitImageForm" v-else>修改</el-button>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import COS from 'cos-js-sdk-v5'
import router from '@/router'
import _ from 'lodash'
import { seriesFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Add',
  components: {
    Breadcrumb,
  },
  mixins: [seriesFormRulesMixin],
  data() {
    return {
      // 添加合成表单数据对象
      id: 0,
      addForm: {
        name:'',
        artist_id: '',
        website_url: '',
        describe: '',
        bg_image: '',
        result_url: '',
        sort: 0,
      },
      artistList: [],
      fileList: [],
      fileurl:'',
      key: '/series/',
    }
  },
  created() {
    const _that = this
    _that.id = router.currentRoute.params.id
    _that.id = isNaN(_that.id) ? 0 : _that.id
    if (_that.id) {
      _that.getDetailById(_that.id)
    }else{
      if (Number(this.$route.query.sort) > 0){
        this.addForm.sort = this.$route.query.sort
      }
    }
    this.getArtistList()
  },
  methods: {
    //是否显示
    setShow(){

    },
    getDetailById(id) {
      const _that = this;
      _that.$http({
        method: 'get',
        url: '/api/v1/admin/art-series/' + id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          if (res.data.status == 200){
            _that.$message.success('获取系列信息成功!')
            var data = res.data.result
            _that.addForm.name = data.name
            _that.addForm.describe = data.describe
            _that.addForm.website_url = data.website_url
            _that.addForm.bg_image = data.bg_image
            _that.addForm.result_url = data.result_url
            _that.addForm.artist_id = data.artist.id
            _that.addForm.sort = data.sort
            _that.fileurl = data.bg_image
          }else{
            _that.$message.error('获取系列信息失败!')
          }
        })
        .catch((res) => {
          _that.$message.error('获取系列列表失败!')
        })
    },
    submitImageForm(){
      let _that = this
      _that.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请将必要的表单项填写完整哦~')
        }
      })

      let time = new Date().getTime()
      let out
      do out = Math.floor(Math.random() * 10000)
      while (out < 1000)
      let key_id = time.toString() + out.toString()

      // let _that = this
      if (this.file) {
        this.cosModel = new COS({
          getAuthorization: function (options, callback) {
            var tx_cos_data = _that.$cookie.get('tx_cos_data')
            tx_cos_data = tx_cos_data ? JSON.parse(tx_cos_data) : ''
            if (tx_cos_data && time < tx_cos_data.result.ExpiredTime / 1000) {
              // let data = JSON.parse(tx_cos_data);
              var credentials = data.result.Credentials
              callback({
                TmpSecretId: credentials.TmpSecretId,
                TmpSecretKey: credentials.TmpSecretKey,
                XCosSecurityToken: credentials.Token,
                ExpiredTime: tx_cos_data.result.ExpiredTime,
              })
            } else {
              _that
                .$http({
                  method: 'get',
                  url: '/api/v1/grab/auth/cos/backend',
                  timeout: 10000,
                  headers: {
                    token: window.sessionStorage.getItem('token'),
                    'Content-Type': 'application/json; charset=UTF-8',
                  },
                })
                .then((res) => {
                  var credentials = res.data.result.Credentials
                  var cookie_data = JSON.stringify(res.data)
                  _that.$cookie.set('tx_cos_data', cookie_data, '1h')
                  callback({
                    TmpSecretId: credentials.TmpSecretId,
                    TmpSecretKey: credentials.TmpSecretKey,
                    XCosSecurityToken: credentials.Token,
                    ExpiredTime: res.data.result.ExpiredTime,
                  })
                })
            }
          },
        })

        let fileName = _that.file.name.lastIndexOf('.') //取到文件名开始到最后一个点的长度
        let fileNameLength = _that.file.name.length
        let fileFormat = _that.file.name.substring(fileName, fileNameLength) //截
        _that.cosModel.putObject(
          {
            Bucket: _that.uploadCos.bucket /* 必须 */,
            Region: _that.uploadCos.region /* 存储桶所在地域，必须字段 */,
            Key: _that.key + key_id + fileFormat /* 必须 */,
            StorageClass: 'STANDARD',
            Body: _that.file.raw, // 上传文件对象
            onProgress: function (progressData) {
              // let return_data = JSON.stringify(progressData)
              // https://rivvoo-1307094776.cos.ap-beijing.myqcloud.com/entity/fa57cfaf29e5f619
            },
          },
          function (err, data) {
            if (data.statusCode == 200) {
              _that.addForm.bg_image =
                'https://' +
                _that.uploadCos.bucket +
                '.cos.' +
                _that.uploadCos.region +
                '.myqcloud.com' +
                _that.key +
                key_id +
                fileFormat
              if (_that.id) {
                _that.submitEditData()
              } else {
                _that.submitData()
              }
            } else {
              _that.$message.success('头像上传失败,请重新上传')
            }
          }
        )
      } else {
        _that.submitEditData()
      }
    },
    submitData() {
      const _that = this
      _that.$refs["addFormRef"].validate((valid) => {
        if (valid) {
          _that.addForm.sort = Number(_that.addForm.sort)
          _that.$http({
            method: 'post',
            url: '/api/v1/admin/art-series',
            data: _that.addForm,
            headers: {
              token: window.sessionStorage.getItem('token'),
              'Content-Type': 'application/json; charset=UTF-8',
            },
            // loading: loading
          })
          .then((res) => {
            if (res.data.status == 200){
              _that.$message.success('新增成功')
              _that.$router.push('/series')
            }else{
              _that.$message.error(res.data.message)
              return
            }
          })
          .catch((res) => {
            return _that.$message.error('新增操作失败了哦,请检查数据后重试')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitEditData() {
      const _that = this
      _that.$refs["addFormRef"].validate((valid) => {
        if (valid) {
          _that.addForm.sort = Number(_that.addForm.sort)
          _that.$http({
            method: 'put',
            url: '/api/v1/admin/art-series/' + _that.id,
            data: _that.addForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              'Content-Type': 'application/json; charset=UTF-8',
            },
          })
          .then((res) => {
            if (res.data.status == 200){
              _that.$message.success('修改成功')
              _that.$router.push('/series')
            }else{
              _that.$message.error(res.data.message)
              return
            }
          })
          .catch((res) => {
            return _that.$message.error('修改操作失败')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getArtistList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/artists',
        params:{page: 1, page_size: 100},
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.artistList = res.data.list
        })
        .catch((res) => {
          return this.$message.error('获取艺术家列表失败!')
        })
    },
    // 点击图片预览时触发
    handlePreview(file) {
      this.file = file
      this.fileurl = URL.createObjectURL(file.raw)
      this.$message.success('图片已选择完毕')
    },
  },
  computed: {},
}
</script>

<style lang="less" scoped>
.el-steps {
  margin: 15px 0;
}
.el-step__title {
  font-size: 13px;
}
.el-checkbox {
  margin: 0 5px 0 0 !important;
}
.perviewImg {
  width: 100%;
}
.AddBtn {
  margin-top: 15px;
}
</style>
